@charset "UTF-8";
.customCalender1:before{
    position: absolute;
    left: 70%;
    top: 3px;
    width: 5px;
    height: 5px;
    border-radius: 50em;
    content: '';
    display: block;
    background-color: #1782ff;
}